import React, { useEffect, useState } from "react";
import "./Product.css";
import SubHeader from "../../../components/subHeader/SubHeader";
import EditIcon from "@mui/icons-material/Edit";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../../FirebaseConfig";
import { login, selectUser } from "../../../redux/features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "../../../components/backbutton/BackButton";

export default function Product({
  productDetails,
  isEditModal,
  updateViewState,
  setIsEditModal,
}) {
  const isAuthorized = process.env.REACT_APP_AUTHORIZED_EMAILS?.split(
    ","
  ).includes(auth.currentUser?.email);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const [quantity, setQuantity] = useState("1");
  const toCapitalize = (str) =>
    str ? str[0].toUpperCase() + str.slice(1).toLowerCase() : "";

  const isInCart = user?.cart?.some(
    (item) =>
      item.id === productDetails.id &&
      item.name === productDetails.name &&
      item.category === productDetails.category
  );
  const [productImageToView, setProductImageToView] = useState(null);

  const [isCartEmtpy, setIsCartEmpty] = useState(true);
  useEffect(() => {
    if (user?.cart?.length > 0) {
      setIsCartEmpty(false);
    } else {
      setIsCartEmpty(true);
    }
  }, []);

  const handleAddToCart = (e) => {
    {
      e.stopPropagation(); // Prevent triggering the parent onClick

      const newCartItem = {
        category: productDetails.category,
        index: productDetails.index,
        subCategory: productDetails.subCategory,
        id: productDetails.id,
        name: productDetails.name,
        image: productDetails.images[0],
        price: productDetails.price,
        totalPrice: productDetails.price,
        quantity: quantity,
      };

      const updatedCart = isInCart
        ? user?.cart?.filter(
            (item) =>
              !(
                item.id === productDetails.id &&
                item.name === productDetails.name &&
                item.category === productDetails.category
              )
          ) // Remove the item if it exists
        : [...(user?.cart || []), newCartItem]; // Add the item if it doesn't exist

      dispatch(
        login({
          ...user,
          cart: updatedCart,
        })
      );
      updateViewState("isCartViewActive", true);
    }
  };
  const navigate = useNavigate();
  return (
    <div className="product">
      <div className="container">
        {/* <button className="back-btn" onClick={() => navigate(-1)}>
          <BackButton />
        </button> */}
        <div className="row d-flex align-items-center">
          {/* Product Image */}

          <div className="product-image col-lg-6 d-flex flex-column justify-content-evenly align-items-center">
            <img
              className="product-main-image"
              src={productImageToView ?? productDetails.images[0]}
              alt={`${productDetails.name}`}
            />
            <div className="product-images d-flex">
              {productDetails.images?.map((item, index) => (
                <img
                  onClick={() => setProductImageToView(item)}
                  key={index}
                  src={item}
                  alt={`${productDetails.name}`}
                />
              ))}
            </div>
          </div>

          {/* Product Details */}
          <div className="product-details col-lg-6 d-flex flex-column justify-content-around">
            <SubHeader
              title={productDetails.category}
              subheader={toCapitalize(productDetails.name)}
            />
            <div className="details-grid">
              {/* Product Name */}
              <div className="detail-row">
                <div className="detail-title">Product Name</div>
                <div className="detail-value">{productDetails.name}</div>
              </div>

              {/* Category */}
              <div className="detail-row">
                <div className="detail-title">Category</div>
                <div className="detail-value">{productDetails.category}</div>
              </div>

              {/* Subcategory */}
              {productDetails.subCategory && (
                <div className="detail-row">
                  <div className="detail-title">Subcategory</div>
                  <div className="detail-value">
                    {productDetails.subCategory || "-"}
                  </div>
                </div>
              )}

              {/* Description */}
              {productDetails.description && (
                <div className="detail-row">
                  <div className="detail-title">Description</div>
                  <div className="detail-value">
                    {productDetails.description.map((item, index) => {
                      const [key, value] = Object.entries(item)[0];
                      return (
                        <div key={index}>
                          <h5>{key}</h5>
                          <ul>
                            {value.map((val, i) => (
                              <li key={i}>{val}</li>
                            ))}
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {/* Price */}
              <div className="detail-row">
                <div className="detail-title">Price</div>
                <div className="detail-value">{`KES ${productDetails.price}`}</div>
              </div>
            </div>
            {/* Quote Section */}
            <div className="quote-div">
              <div className="row d-flex align-items-center ">
                <div className="col-lg-3">
                  <label>Quantity:</label>
                  <br />
                  <input
                    type="number"
                    placeholder="1"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                </div>
                <div className="col-lg-6">
                  <button
                    className="edit-btn"
                    onClick={() => {
                      setIsEditModal(true);
                    }}
                  >
                    <EditIcon className="edit-btn-icon" /> Edit{" "}
                    {productDetails.name}
                  </button>
                </div>
              </div>
            </div>
            {/* <Link to={"/checkout"} className="link">
              <div className="checkout-btn">Checkout</div>
            </Link> */}
          </div>
        </div>
      </div>

      <SubHeader title="More Products" />
    </div>
  );
}
