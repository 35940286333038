import React, { useEffect, useState } from "react";
import "./EditProduct.css";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { ref, update, get } from "firebase/database";
import { db } from "../../../../FirebaseConfig";
import Swal from "sweetalert2";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";

export default function EditProduct({
  productDetails,
  setIsEditModal,
  categoriesWithSubcategories,
  products,
}) {
  const [editedProductDetails, setEditedProductDetails] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const { categoryIndex, subcategoryIndex, productIndex } = productDetails;
  const [selectedImages, setSelectedImages] = useState([]); // To store newly selected images

  useEffect(() => {
    if (productDetails) {
      const filteredKeys = Object.keys(productDetails).filter(
        (key) =>
          key !== "id" &&
          key !== "categoryIndex" &&
          key !== "subcategoryIndex" &&
          key !== "productIndex"
      );
      const initialProductDetails = filteredKeys.reduce((acc, key) => {
        acc[key] = productDetails[key];
        return acc;
      }, {});

      setEditedProductDetails(initialProductDetails);
      setSelectedCategory(productDetails.category);
      setSelectedSubCategory(productDetails.subCategory);
    }
  }, [productDetails]);

  const handleProductDetailsChange = (field, value) => {
    setEditedProductDetails((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleCategoryChange = (event) => {
    const newCategory = event.target.value;
    setSelectedCategory(newCategory);
    if (Array.isArray(categoriesWithSubcategories[newCategory])) {
      setSelectedSubCategory(categoriesWithSubcategories[newCategory][0]);
    } else {
      setSelectedSubCategory(""); // Handle case if no subcategories exist
    }
    handleProductDetailsChange("category", newCategory);
  };

  const handleSubCategoryChange = (event) => {
    setSelectedSubCategory(event.target.value);
    handleProductDetailsChange("subCategory", event.target.value);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        Swal.fire(
          "Error",
          "Image file is too large. Max size is 5MB.",
          "error"
        );
        return;
      }

      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!allowedTypes.includes(file.type)) {
        Swal.fire(
          "Error",
          "Invalid file type. Only JPEG, JPG, and PNG are allowed.",
          "error"
        );
        return;
      }

      setSelectedImages((prev) => [...prev, file]); // Add new image to selectedImages array
    }
  };

  const handleAddMorePhotos = (event) => {
    const files = Array.from(event.target.files);
    const validFiles = files.filter(
      (file) =>
        file.size <= 5 * 1024 * 1024 &&
        ["image/jpeg", "image/png", "image/jpg"].includes(file.type)
    );

    if (validFiles.length < files.length) {
      Swal.fire(
        "Error",
        "Some files were invalid or too large. Only valid files were added.",
        "error"
      );
    }

    setSelectedImages((prev) => [...prev, ...validFiles]);
  };

  const handleSaveProduct = async () => {
    setIsEditModal(false);

    let basePath =
      subcategoryIndex !== null && subcategoryIndex !== undefined
        ? `products/${categoryIndex}/products/${subcategoryIndex}/products/${productIndex}`
        : `products/${categoryIndex}/products/${productIndex}`;

    try {
      const updates = {};

      // Upload images if any were added
      if (selectedImages.length > 0) {
        Swal.fire({
          title: "Uploading Images...",
          html: "Please wait while the images are being uploaded.",
          allowOutsideClick: false,
          willOpen: () => {
            Swal.showLoading();
          },
        });

        const uploadedImageUrls = await Promise.all(
          selectedImages.map(async (image) => {
            const formData = new FormData();
            formData.append("file", image);
            formData.append(
              "upload_preset",
              process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
            );
            formData.append(
              "cloud_name",
              process.env.REACT_APP_CLOUDINARY_CLOUD_NAME
            );

            const response = await axios.post(
              `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`,
              formData
            );
            return response.data.secure_url;
          })
        );

        // Fetch existing images from Firebase if they exist
        const existingImagesSnapshot = await get(ref(db, `${basePath}/images`));
        const existingImages = existingImagesSnapshot.exists()
          ? existingImagesSnapshot.val()
          : [];

        // Append new images to the existing ones
        const allImageUrls = [...existingImages, ...uploadedImageUrls];

        updates[`${basePath}/images`] = allImageUrls;
      }

      // Save other product details
      Object.keys(editedProductDetails).forEach((key) => {
        if (key !== "images" && editedProductDetails[key] !== "") {
          updates[`${basePath}/${key}`] = editedProductDetails[key];
        }
      });

      // Perform updates
      for (const [path, value] of Object.entries(updates)) {
        await update(ref(db), { [path]: value });
      }

      Swal.fire({
        icon: "success",
        title: "Product Updated Successfully",
        text: "The product details have been updated.",
      });

      setEditedProductDetails({});
    } catch (error) {
      console.error("Error saving product details to Firebase:", error.message);
      Swal.fire({
        icon: "error",
        title: "Save Failed",
        text: "An error occurred while saving the product details.",
      });
    }
  };

  const handleDeleteImage = async (indexToDelete, isNew) => {
    try {
      // Show a confirmation Swal first
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to delete this image?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
        didOpen: () => {
          const swalModal = document.querySelector(".swal2-container");
          if (swalModal) {
            swalModal.style.zIndex = "9999"; // Adjust z-index here
          }
        },
      });

      // If the user confirms deletion
      if (result.isConfirmed) {
        if (isNew) {
          setSelectedImages((prev) =>
            prev.filter((_, index) => index !== indexToDelete)
          );
          console.log("didnt delete from database");
        } else {
          console.log("deleting from database");
          console.log(subcategoryIndex);

          const updatedImages = editedProductDetails.images.filter(
            (_, index) => index !== indexToDelete
          );
          setEditedProductDetails((prev) => ({
            ...prev,
            images: updatedImages,
            id: productIndex,
          }));

          // const basePath = `products/${categoryIndex}/products/${
          //   subcategoryIndex || ""
          // }/products/${productIndex}/images`;
          let basePath =
            subcategoryIndex !== null && subcategoryIndex !== undefined
              ? `products/${categoryIndex}/products/${subcategoryIndex}/products/${productIndex}`
              : `products/${categoryIndex}/products/${productIndex}`;

          await update(ref(db), { [basePath]: updatedImages });

          Swal.fire({
            icon: "success",
            title: "Image Deleted",
            text: "The image has been successfully deleted.",
            didOpen: () => {
              const swalModal = document.querySelector(".swal2-container");
              if (swalModal) {
                swalModal.style.zIndex = "9999"; // Adjust z-index here
              }
            },
          });
        }
      } else {
        // If the user cancels the deletion, do nothing but keep the modal open
        Swal.fire({
          icon: "info",
          title: "Deletion Cancelled",
          text: "The image deletion was cancelled.",
          didOpen: () => {
            const swalModal = document.querySelector(".swal2-container");
            if (swalModal) {
              swalModal.style.zIndex = "9999"; // Adjust z-index here
            }
          },
        });
      }
    } catch (error) {
      console.error("Error deleting image:", error);
      Swal.fire({
        icon: "error",
        title: "Delete Failed",
        text: "An error occurred while deleting the image.",
        didOpen: () => {
          const swalModal = document.querySelector(".swal2-container");
          if (swalModal) {
            swalModal.style.zIndex = "9999"; // Adjust z-index here
          }
        },
      });
    }
  };

  const handleRemoveImageFromSelectedImages = (indexToDelete) => {
    setSelectedImages((prev) =>
      prev.filter((_, index) => index !== indexToDelete)
    );
  };

  const handleCancel = () => {
    setIsEditModal(false);
  };

  return (
    <div className="edit-product">
      <div className="container">
        <div className="d-flex flex-column justify-content-center align-items-center ">
          <div className="images d-flex align-items-center justify-content-around">
            {/* Render existing database images */}
            {editedProductDetails.images?.map((img, index) => (
              <div
                key={`db-${index}`}
                className={`image-div ${index === 0 ? "main" : ""}`}
                style={{ backgroundImage: `url(${img})` }}
              >
                <div className="update-photo">
                  <label htmlFor={`upload-photo-${productIndex}-${index}`}>
                    <AddAPhotoIcon className="add-photo-icon" />
                    <input
                      type="file"
                      id={`upload-photo-${productIndex}-${index}`}
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleImageChange}
                    />
                  </label>
                  <button
                    className="delete-image-btn"
                    onClick={() => handleDeleteImage(index, false)}
                  >
                    <DeleteIcon className="delete-photo-icon" />
                  </button>
                </div>
              </div>
            ))}
            {/* Render newly selected images */}
            {selectedImages.map((img, index) => (
              <div
                key={`new-${index}`}
                className={`image-div`}
                style={{
                  backgroundImage: `url(${URL.createObjectURL(img)})`,
                }}
              >
                <div className="update-photo">
                  {/* <label htmlFor={`upload-photo-${productIndex}-${index}`}>
                    <AddAPhotoIcon className="add-photo-icon" />
                    <input
                      type="file"
                      id={`upload-photo-${productIndex}-${index}`}
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleImageChange}
                    />
                  </label> */}
                  <button
                    className="delete-image-btn"
                    onClick={() => handleRemoveImageFromSelectedImages(index)}
                  >
                    <DeleteIcon className="delete-photo-icon" />
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="add-more-photos">
            <label htmlFor="add-more-photos">
              <AddToPhotosIcon className="add-more-photo-icon" />
              <input
                type="file"
                id="add-more-photos"
                accept="image/*"
                multiple
                style={{ display: "none" }}
                onChange={handleAddMorePhotos}
              />
            </label>
          </div>

          <div className="details-grid">
            {/* Product Name */}
            <div className="detail-row">
              <div className="detail-title">Product Name</div>
              <input
                className="detail-value"
                value={editedProductDetails.name}
                placeholder={productDetails.name}
                onChange={(e) =>
                  handleProductDetailsChange("name", e.target.value)
                }
              />
            </div>

            {/* Category Dropdown */}
            <div className="detail-row">
              <div className="detail-title">Category</div>
              <select
                className="detail-value"
                value={selectedCategory}
                onChange={handleCategoryChange}
                disabled
              >
                {Object.keys(categoriesWithSubcategories).map(
                  (category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  )
                )}
              </select>
            </div>

            {/* Subcategory Dropdown */}
            {selectedCategory && (
              <div className="detail-row">
                <div className="detail-title">Subcategory</div>
                <select
                  className="detail-value"
                  value={selectedSubCategory}
                  onChange={handleSubCategoryChange}
                  disabled
                >
                  {categoriesWithSubcategories[selectedCategory].map(
                    (subCategory, index) => (
                      <option key={index} value={subCategory}>
                        {subCategory || "No Subcategory"}
                      </option>
                    )
                  )}
                </select>
              </div>
            )}

            {/* Description */}
            {productDetails.description && (
              <div className="detail-row">
                <div className="detail-title">Description</div>
                <div className="detail-value">
                  {productDetails.description.map((item, index) => {
                    const [key, value] = Object.entries(item)[0];
                    return (
                      <div key={index}>
                        <h5>{key}</h5>
                        <ul>
                          {value.map((val, i) => (
                            <li key={i}>
                              <input
                                value={
                                  editedProductDetails?.description?.[index]?.[
                                    key
                                  ]?.[i] || ""
                                }
                                placeholder={val}
                                onChange={(e) => {
                                  const updatedDescription = [
                                    ...productDetails.description,
                                  ];
                                  const targetObject = {
                                    ...updatedDescription[index],
                                  };
                                  const targetArray = [...targetObject[key]];

                                  targetArray[i] = e.target.value;

                                  targetObject[key] = targetArray;
                                  updatedDescription[index] = targetObject;

                                  handleProductDetailsChange(
                                    "description",
                                    updatedDescription
                                  );
                                }}
                              />
                            </li>
                          ))}
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {/* Price */}
            <div className="detail-row">
              <div className="detail-title">Price (KES)</div>
              <input
                className="detail-value"
                value={editedProductDetails.price}
                placeholder={productDetails.price}
                onChange={(e) =>
                  handleProductDetailsChange("price", e.target.value)
                }
              />
            </div>
          </div>

          <div className="buttons">
            <button onClick={handleCancel} className="cancel-btn">
              Cancel
            </button>
            <button onClick={handleSaveProduct} className="save-btn">
              Save Product
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
